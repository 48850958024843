import { API } from 'config/api';

const action = {
  SUSCRIPCION_FETCH_REQUEST: 'empresa/SUSCRIPCION_FETCH_REQUEST',
  SUSCRIPCION_FETCH_SUCCESS: 'empresa/SUSCRIPCION_FETCH_SUCCESS',
  SUSCRIPCION_FETCH_ERROR: 'empresa/SUSCRIPCION_FETCH_ERROR',

  SUSCRIPCIONS_CONTACTO_REQUEST: 'empresa/SUSCRIPCIONS_CONTACTO_REQUEST',
  SUSCRIPCIONS_CONTACTO_SUCCESS: 'empresa/SUSCRIPCIONS_CONTACTO_SUCCESS',
  SUSCRIPCIONS_CONTACTO_ERROR: 'empresa/SUSCRIPCION_FETCH_ERROR',

  SUSCRIPCION_CREATE_REQUEST: 'empresa/SUSCRIPCION_CREATE_REQUEST',
  SUSCRIPCION_CREATE_SUCCESS: 'empresa/SUSCRIPCION_CREATE_SUCCESS',
  SUSCRIPCION_CREATE_ERROR: 'empresa/SUSCRIPCION_CREATE_ERROR',

  SUSCRIPCION_UPDATE_REQUEST: 'empresa/SUSCRIPCION_UPDATE_REQUEST',
  SUSCRIPCION_UPDATE_SUCCESS: 'empresa/SUSCRIPCION_UPDATE_SUCCESS',
  SUSCRIPCION_UPDATE_ERROR: 'empresa/SUSCRIPCION_UPDATE_ERROR',

  SUSCRIPCION_DELETE_REQUEST: 'empresa/SUSCRIPCION_DELETE_REQUEST',
  SUSCRIPCION_DELETE_SUCCESS: 'empresa/SUSCRIPCION_DELETE_SUCCESS',
  SUSCRIPCION_DELETE_ERROR: 'empresa/SUSCRIPCION_DELETE_ERROR',

  UPDATE_ADMIN_SUSCRIPCIONS_SUCCESS: 'empresa/UPDATE_ADMIN_SUSCRIPCIONS_SUCCESS',
  CLOSE_DRAW_CONTACTOS: 'empresa/CLOSE_DRAW_CONTACTOS',

  OPEN_MODAL_SUSCRIPCION: 'empresa/OPEN_MODAL_SUSCRIPCION',

  getSuscripcions: () => (dispatch) => {
    dispatch({ type: action.SUSCRIPCION_FETCH_REQUEST });
    API.get(`/admin/suscripciones`)
      .then((res) => {
        dispatch({ type: action.SUSCRIPCION_FETCH_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: action.SUSCRIPCION_FETCH_ERROR });
      });
  },

  addSuscripcion: (data) => ({
    types: [action.SUSCRIPCION_CREATE_REQUEST, action.SUSCRIPCION_CREATE_SUCCESS, action.SUSCRIPCION_CREATE_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'POST',
        url: '/admin/suscripciones',
        data,
      },
    },
  }),

  updateSuscripcion: (data, id) => ({
    types: [action.SUSCRIPCION_UPDATE_REQUEST, action.SUSCRIPCION_UPDATE_SUCCESS, action.SUSCRIPCION_UPDATE_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'PUT',
        url: `/customer/${id}/update`,
        data,
      },
    },
  }),

  deleteSuscripcion: (id) => (dispatch) => {
    dispatch({ type: action.SUSCRIPCION_DELETE_REQUEST });
    API.delete(`/customer/${id}/delete`)
      .then((res) => {
        dispatch({ type: action.SUSCRIPCION_DELETE_SUCCESS, payload: id });
      })
      .catch((err) => {
        dispatch({ type: action.SUSCRIPCION_DELETE_ERROR });
      });
  },

  openModal: (payload, modalType) => (dispatch) => {
    dispatch({ type: action.OPEN_MODAL_SUSCRIPCION, payload, modalType });
  },
};
export default action;
