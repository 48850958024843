import axios from "axios/index";

// Api default
const apiClient = (baseUrl) =>
  axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

const clients = {
  default: {
    client: apiClient(process.env.REACT_APP_API_URL),
  },
};

export default clients;
