import { API } from 'config/api';

const action = {
  PLAN_FETCH_REQUEST: 'empresa/PLAN_FETCH_REQUEST',
  PLAN_FETCH_SUCCESS: 'empresa/PLAN_FETCH_SUCCESS',
  PLAN_FETCH_ERROR: 'empresa/PLAN_FETCH_ERROR',

  PLANS_CONTACTO_REQUEST: 'empresa/PLANS_CONTACTO_REQUEST',
  PLANS_CONTACTO_SUCCESS: 'empresa/PLANS_CONTACTO_SUCCESS',
  PLANS_CONTACTO_ERROR: 'empresa/PLAN_FETCH_ERROR',

  PLAN_CREATE_REQUEST: 'empresa/PLAN_CREATE_REQUEST',
  PLAN_CREATE_SUCCESS: 'empresa/PLAN_CREATE_SUCCESS',
  PLAN_CREATE_ERROR: 'empresa/PLAN_CREATE_ERROR',

  PLAN_UPDATE_REQUEST: 'empresa/PLAN_UPDATE_REQUEST',
  PLAN_UPDATE_SUCCESS: 'empresa/PLAN_UPDATE_SUCCESS',
  PLAN_UPDATE_ERROR: 'empresa/PLAN_UPDATE_ERROR',

  PLAN_DELETE_REQUEST: 'empresa/PLAN_DELETE_REQUEST',
  PLAN_DELETE_SUCCESS: 'empresa/PLAN_DELETE_SUCCESS',
  PLAN_DELETE_ERROR: 'empresa/PLAN_DELETE_ERROR',

  UPDATE_ADMIN_PLANS_SUCCESS: 'empresa/UPDATE_ADMIN_PLANS_SUCCESS',
  CLOSE_DRAW_CONTACTOS: 'empresa/CLOSE_DRAW_CONTACTOS',

  OPEN_MODAL_PLAN: 'empresa/OPEN_MODAL_PLAN',
  OPEN_MODAL_CONFIRM: 'empresa/OPEN_MODAL_CONFIRM',

  getPlans: () => (dispatch) => {
    dispatch({ type: action.PLAN_FETCH_REQUEST });
    API.get(`/planes`)
      .then((res) => {
        dispatch({ type: action.PLAN_FETCH_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: action.PLAN_FETCH_ERROR });
      });
  },

  addPlan: (data) => ({
    types: [action.PLAN_CREATE_REQUEST, action.PLAN_CREATE_SUCCESS, action.PLAN_CREATE_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'POST',
        url: '/planes',
        data,
      },
    },
  }),

  updatePlan: (data, id) => ({
    types: [action.PLAN_UPDATE_REQUEST, action.PLAN_UPDATE_SUCCESS, action.PLAN_UPDATE_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'PUT',
        url: `/planes/${id}`,
        data,
      },
    },
  }),

  deletePlan: (id) => (dispatch) => {
    dispatch({ type: action.PLAN_DELETE_REQUEST });
    API.delete(`/planes/${id}`)
      .then((res) => {
        dispatch({ type: action.PLAN_DELETE_SUCCESS, payload: id });
      })
      .catch((err) => {
        dispatch({ type: action.PLAN_DELETE_ERROR });
      });
  },

  openModal: (payload, modalType) => (dispatch) => {
    dispatch({ type: action.OPEN_MODAL_PLAN, payload, modalType });
  },

  showConfirm: (payload) => (dispatch) => {
    dispatch({ type: action.OPEN_MODAL_CONFIRM, payload });
  },
};
export default action;
