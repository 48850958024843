import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/core';

export default () => {
  return (
    <Flex h="100vh" justifyContent="center" alignItems="center">
      <Box>
        <Text fontSize="30px" color="gray.500">
          Dashboard
        </Text>
      </Box>
    </Flex>
  );
};
