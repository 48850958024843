import React, { lazy, Suspense } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthProvider from './context/auth';
import Loader from './components/Loader';
import Layout from './containers/Layout/Layout';
import ErrorBoundary from './ErrorBoundary';
import LayoutAdmin from 'appAdmin/Layout/Layout';
import Dashboard from 'appAdmin/Dashboard';
import Empresas from 'appAdmin/Empresas';
import Suscripciones from 'appAdmin/Suscripciones';
import Planes from 'appAdmin/Planes';

const publicRoutes = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('./containers/Pages/Login')),
  },
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('./containers/Pages/Login')),
  },
];
function PrivateRoute({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.auth.token);
  if (isLoggedIn) return <Route {...rest}>{children}</Route>;
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
}
const NotFound = () => <div>Pagina no encontrada</div>;
export const Routes = () => {
  return (
    <AuthProvider>
      <ErrorBoundary>
        <Router>
          <Suspense fallback={<Loader />}>
            <Switch>
              {publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact}>
                  <Layout>
                    <route.component />
                  </Layout>
                </Route>
              ))}
              <PrivateRoute path="/dashboard" exact>
                <LayoutAdmin>
                  <Dashboard />
                </LayoutAdmin>
              </PrivateRoute>

              <PrivateRoute path="/empresas" exact>
                <LayoutAdmin>
                  <Empresas />
                </LayoutAdmin>
              </PrivateRoute>

              <PrivateRoute path="/suscripciones" exact>
                <LayoutAdmin>
                  <Suscripciones />
                </LayoutAdmin>
              </PrivateRoute>

              <PrivateRoute path="/planes" exact>
                <LayoutAdmin>
                  <Planes />
                </LayoutAdmin>
              </PrivateRoute>

              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      </ErrorBoundary>
    </AuthProvider>
  );
};
