// import { push } from 'react-router-redux';
// import { API } from 'config/api';
export function getView(width) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}
const actions = {
  COLLPSE_CHANGE: 'COLLPSE_CHANGE',
  COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  CLOSE_ALL: 'CLOSE_ALL',
  SET_SERVICE: 'SET_SERVICE',
  SET_RESOURCE: 'SET_RESOURCE',
  RESOURCES_FETCH_REQUEST: 'app/RESOURCES_FETCH_REQUEST',
  RESOURCES_FETCH_SUCCESS: 'app/RESOURCES_FETCH_SUCCESS',
  RESOURCES_FETCH_ERROR: 'app/RESOURCES_FETCH_ERROR',
  SERVICES_FETCH_REQUEST: 'app/SERVICES_FETCH_REQUEST',
  SERVICES_FETCH_SUCCESS: 'app/SERVICES_FETCH_SUCCESS',
  SERVICES_FETCH_ERROR: 'app/SERVICES_FETCH_ERROR',
  CLEAN_ALL: 'app/CLEAN_ALL',
  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE,
  }),
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== 'DesktopView';
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER,
  }),
  changeOpenKeys: (openKeys) => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys,
  }),
  changeCurrent: (current) => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),
  closeAll: () => ({ type: actions.CLOSE_ALL }),

  getResourcesBySlug: (data) => ({
    types: [actions.RESOURCES_FETCH_REQUEST, actions.RESOURCES_FETCH_SUCCESS, actions.RESOURCES_FETCH_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'GET',
        url: '/resource/list?page=1',
        data,
      },
    },
  }),

  getServicesBySlug: (data) => ({
    types: [actions.SERVICES_FETCH_REQUEST, actions.SERVICES_FETCH_SUCCESS, actions.SERVICES_FETCH_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'GET',
        url: '/service/list?page=1',
        data,
      },
    },
  }),
  cleanAll: () => ({ type: actions.CLEAN_ALL }),
  // getResourcesBySlug: (slug) => (dispatch) => {
  //   dispatch({ type: actions.RESOURCES_FETCH_REQUEST });
  //   API.get(`/resource/list?page=1`)
  //     .then((res) => {
  //       dispatch({ type: actions.RESOURCES_FETCH_SUCCESS, payload: res.data });
  //     })
  //     .catch((err) => {
  //       dispatch({ type: actions.RESOURCES_FETCH_ERROR });
  //     });
  // },

  // getServicesBySlug: (slug) => (dispatch) => {
  //   dispatch({ type: actions.SERVICES_FETCH_REQUEST });
  //   API.get(`/service/list?page=1`)
  //     .then((res) => {
  //       dispatch({ type: actions.SERVICES_FETCH_SUCCESS, payload: res.data });
  //     })
  //     .catch((err) => {
  //       dispatch({ type: actions.SERVICES_FETCH_ERROR });
  //     });
  // },
};
export default actions;
