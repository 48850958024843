import auth from './auth/reducer';
import app from './app/reducer';
import empresa from './empresa/reducer';
import suscripcion from './suscripcion/reducer';
import plan from './plan/reducer';

export default {
  auth,
  app,
  empresa,
  suscripcion,
  plan,
};
