import React from "react";
import {
  ThemeProvider,
  theme,
  ColorModeProvider,
  CSSReset,
} from "@chakra-ui/core";
import Container from "./container";
import "react-day-picker/lib/style.css";
export default function Layout(props) {
  const { children } = props;
  return (
    <ThemeProvider theme={theme}>
      <ColorModeProvider>
        <CSSReset />
        <Container>{children}</Container>
      </ColorModeProvider>
    </ThemeProvider>
  );
}
