import jwtDecode from 'jwt-decode';
class AuthHelper {
  checkExpirity = (token) => {
    if (!token) {
      return {
        error: 'not matched',
      };
    }
    try {
      const profile = jwtDecode(token);
      const expiredAt = profile.expiredAt || profile.exp * 1000;
      // console.log('expiracion', new Date(expiredAt));
      if (expiredAt > new Date().getTime()) {
        return {
          ...profile,
          token,
          expiredAt: new Date(expiredAt),
        };
      } else {
        return { error: 'Token expired' };
      }
    } catch (e) {
      console.log(e);

      return { error: 'Server Error' };
    }
  };
}
export default new AuthHelper();
