import axios from 'axios';
import { toast } from 'react-toastify';
// import notification from "components/Notifications";
import Cookies from 'js-cookie';
import config from 'config';

export const API = axios.create({
  baseURL: config.api,
  headers: {
    token: `${Cookies.get('id_token')}` || undefined,
    'Content-Type': 'application/json',
  },
});

API.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      token: `${Cookies.get('id_token')}` || undefined,
      'Content-Type': 'application/json',
    };
    // you can also do other modification in config
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    console.log('error', response);
    // toast.error(response.data.message);
    toast.error(response.data.err.description);
    // console.log(response.data);
    // notification.error(response.data.error);
    // notification("error", response.data.error.message);
    return Promise.reject(error.response.data);
  }
);
