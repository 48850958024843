import React, { Fragment } from 'react';
import { Box, Skeleton, useColorMode } from '@chakra-ui/core';

export default ({ total }) => {
  const { colorMode } = useColorMode();
  const bgColor = { light: 'white', dark: '#394353' };
  const array = Array(total ? total : 11)
    .fill()
    .map((_, i) => i);
  return (
    <Fragment>
      {array.map((i) => (
        <Box key={i} display="flex" bg={bgColor[colorMode]} p={1}>
          <Box width="30px" mr="20px">
            <Skeleton background="#f2f2f2" height="30px" my="10px" borderRadius="50px" />
          </Box>
          <Box flex="1">
            <Skeleton width="50%" height="13px" my="10px" />
            <Skeleton height="13px" my="10px" />
            {/* <Skeleton height="13px" my="10px" /> */}
            {/* <Skeleton height="13px" my="10px" /> */}
          </Box>
        </Box>
      ))}
    </Fragment>
  );
};
