import React from 'react';
import { ThemeProvider, theme, ColorModeProvider, CSSReset } from '@chakra-ui/core';
import Container from './container';

// import 'react-day-picker/lib/style.css';

export default function Layout(props) {
  const { children } = props;
  const breakpoints = ['360px', '768px', '1024px', '1440px'];
  breakpoints.sm = breakpoints[0];
  breakpoints.md = breakpoints[1];
  breakpoints.lg = breakpoints[2];
  breakpoints.xl = breakpoints[3];
  const newTheme = {
    ...theme,
    breakpoints,
  };

  return (
    <ThemeProvider theme={newTheme}>
      <ColorModeProvider>
        <CSSReset />
        <Container>{children}</Container>
      </ColorModeProvider>
    </ThemeProvider>
  );
}
