import actions from "./actions";

const initialState = {
  isAuthenticated: false,
  currentUser: {},
  error: false,
  loading: false,
  token: null,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actions.AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: action.authenticated,
      };

    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        isAuthenticated: false,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: payload,
        isAuthenticated: true,
        token: action.token,
      };
    case actions.LOGIN_SUCCESS_CHECK:
      return {
        ...state,
        token: action.token,
        currentUser: payload,
        isAuthenticated: true,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };

    case actions.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
