import actions from './actions';

const initialState = {
  loading: false,
  cargando: false,
  visible: false,
  pagination: {},
  cuenta: {},
  showModal: false,
  modalType: 'create',
  list: [],
  currentItem: {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    notes: '',
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.CUSTOMER_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.CUSTOMER_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        pagination: payload.pagination,
        query: action.query,
      };
    case actions.CUSTOMER_FETCH_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.CUSTOMER_CREATE_REQUEST:
      return {
        ...state,
        loadingForm: true,
      };
    case actions.CUSTOMER_CREATE_SUCCESS:
      return {
        ...state,
        loadingForm: false,
        list: [...state.list, payload.data],
        visible: false,
      };
    case actions.CUSTOMER_CREATE_ERROR:
      return {
        ...state,
        loadingForm: false,
      };
    case actions.CUSTOMER_UPDATE_REQUEST:
      return {
        ...state,
        loadingForm: true,
      };
    case actions.CUSTOMER_UPDATE_SUCCESS:
      return {
        ...state,
        loadingForm: false,
        list: state.list.map((x) => (x.email === payload.data.email ? payload.data : x)),
        visible: false,
        modalType: 'create',
        currentItem: initialState.currentItem,
      };
    case actions.CUSTOMER_UPDATE_ERROR:
      return {
        ...state,
        loadingForm: false,
      };
    case actions.CUSTOMER_DELETE_REQUEST:
      return {
        ...state,
        loadingForm: true,
      };
    case actions.CUSTOMER_DELETE_SUCCESS:
      return {
        ...state,
        loadingForm: false,
        list: state.list.filter((item) => item._id !== payload),
      };
    case actions.CUSTOMER_DELETE_ERROR:
      return {
        ...state,
        loadingForm: false,
        error: true,
        // message: error.
      };
    case actions.OPEN_MODAL_CUSTOMER:
      return {
        ...state,
        visible: !state.visible,
        currentItem: payload || initialState.currentItem,
        modalType: action.modalType,
      };

    default:
      return state;
  }
};
