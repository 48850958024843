import React from "react";
import Cookies from "js-cookie";
// import AuthHelper from "lib/helpers/authHelper";
// import { useHistory } from "react-router-dom";

export const AuthContext = React.createContext({});

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  loading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

const AuthProvider = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  // let history = useHistory();
  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || null);
    const token = Cookies.get("token") || null;
    if (user && token) {
      dispatch({
        type: "LOGIN",
        payload: {
          user,
          token,
          loading: false,
        },
      });
    } else {
      Cookies.remove("token");
      // history.push("/");
    }
  }, []);

  const isValidToken = () => {
    // const { token } = AuthHelper.checkExpirity(Cookies.get("token"));
    // JWT decode & check token validity & expiration.
    // if (token) return true;
    return false;
  };
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());

  function authenticate(token, cb) {
    // console.log("entra aca wey");
    makeAuthenticated(true);
    Cookies.set("token", token);
    localStorage.setItem("token", token);
  }
  function signout(cb) {
    makeAuthenticated(false);
    localStorage.removeItem("token");
    Cookies.remove("token");
    setTimeout(cb, 100);
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        isAuthenticated,
        authenticate,
        signout,
        user: state.user,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
