import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalHeader, Button } from '@chakra-ui/core';
import { useColorMode, SimpleGrid } from '@chakra-ui/core';
import { ChakraInput, ChakraText } from 'components/InputForm';
import actions from '@redux/empresa/actions';

const { openModal, addCustomer, updateCustomer } = actions;

const validationSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    email: Yup.string('Required').required('Required').nullable(),
  });
};

export default ({ onClose, onUpdate }) => {
  const dispatch = useDispatch();
  const visible = useSelector((state) => state.empresa.visible);
  const item = useSelector((state) => state.empresa.currentItem);
  const type = useSelector((state) => state.empresa.modalType);
  const loading = useSelector((state) => state.empresa.loadingForm);
  const onSubmit = async (values) => {
    if (type === 'create') {
      values.currentStatus = 'Active';
      dispatch(addCustomer(values));
    } else {
      dispatch(updateCustomer(values, item._id));
    }
  };
  const { colorMode } = useColorMode();
  const color = { light: 'gray.800', dark: '#fff' };
  return (
    <Formik enableReinitialize initialValues={item} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, submitCount, resetForm }) => {
        const onCerrar = () => {
          dispatch(openModal());
          resetForm();
        };
        return (
          <Modal size="xl" isOpen={visible} onClose={onCerrar} closeOnEsc={false} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent color={color[colorMode]}>
              <ModalHeader>{type === 'create' ? 'Add empresa' : 'Edit empresa'}</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={4}>
                <Form onSubmit={handleSubmit}>
                  <SimpleGrid columns={[1, 1, 2]} spacingX="25px">
                    <Field
                      label="Customer name"
                      placeholder="Customer name"
                      component={ChakraInput}
                      type="text"
                      submitCount={submitCount}
                      name="firstName"
                    />
                    <Field
                      label="Customer last name"
                      placeholder="Customer last"
                      component={ChakraInput}
                      type="text"
                      submitCount={submitCount}
                      name="lastName"
                    />
                    <Field
                      label="Phone"
                      placeholder="Mobile phone"
                      component={ChakraInput}
                      type="text"
                      submitCount={submitCount}
                      name="phone"
                    />
                    <Field
                      label="Email"
                      placeholder="Email"
                      component={ChakraInput}
                      type="text"
                      submitCount={submitCount}
                      name="email"
                      autoComplete="new-something"
                    />
                  </SimpleGrid>

                  <Field
                    fontSize="13px"
                    color={color[colorMode]}
                    component={ChakraText}
                    name="notes"
                    label="Notes"
                    placeholder="Additional notes"
                    submitCount={submitCount}
                  />
                  <Button width="100%" variantColor="purple" isLoading={loading} type="submit" height="45px" my={3}>
                    Guardar
                  </Button>
                </Form>
              </ModalBody>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};
