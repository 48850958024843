import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Box, useColorMode, Button } from '@chakra-ui/core';
import { Icon } from '@chakra-ui/core';
import useWindowSize from 'hooks/useWindowSize';

const Container = ({ children }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = { light: '#FBFBFD', dark: 'gray.800' };
  const { height } = useWindowSize();
  return (
    <Box minH={height} bg={bgColor[colorMode]}>
      <Box>
        {children}{' '}
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <Box display={{ base: 'none', md: 'block' }} position="fixed" right="20px" bottom="20px">
          <Button onClick={toggleColorMode}>{colorMode === 'dark' ? <Icon name="sun" /> : <Icon name="moon" />}</Button>
        </Box>
        <Box display={{ base: 'block', md: 'none' }} position="fixed" right="20px" bottom="20px" zIndex="999">
          <Button size="sm" onClick={toggleColorMode}>
            {colorMode === 'dark' ? <Icon name="sun" /> : <Icon name="moon" />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Container;
