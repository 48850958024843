import React, { useState, useEffect, Fragment } from 'react';
import queryString from 'query-string';
import Fuse from 'fuse.js';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Flex, Box, useColorMode, Text, Input } from '@chakra-ui/core';
import { FcInfo } from 'react-icons/fc';
import { isEmpty } from 'lodash';
import Loading from 'components/LoadingList';
import actions from '@redux/empresa/actions';
// import SearchForm from './SearchForm';
import ListCustomers from './ListCustomers';
import ModalForm from './Modal';

const { getCustomers, openModal, deleteCustomer } = actions;

const fuseOptions = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 50,
  maxPatternLength: 12,
  minMatchCharLength: 3,
  keys: ['razonSoc', 'phone', 'email'],
};

export default () => {
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  let location = useLocation();
  const loading = useSelector((state) => state.empresa.loading);
  const customers = useSelector((state) => state.empresa.list);
  const { colorMode } = useColorMode();
  const bgColor = { light: 'white', dark: 'gray.800' };
  const color = { light: 'gray.800', dark: 'gray.100' };

  useEffect(() => {
    const values = queryString.parse(location.search);
    const data = { page: values.page || 1 };
    dispatch(getCustomers(data));
  }, [dispatch, location.search]);

  const noItemsComponent = (
    <Flex h="65vh" alignItems="center" justifyContent="center" flexDirection="column">
      <Box as={FcInfo} size="70px" mb={5} />
      <Text fontSize="30px"> No data to display</Text>
    </Flex>
  );

  const onEdit = (item) => {
    dispatch(openModal(item, 'update'));
  };

  const onDelete = (id) => {
    dispatch(deleteCustomer(id));
  };
  const filtered = React.useMemo(() => {
    if (!query || !customers) return customers || [];
    const fuse = new Fuse(customers, fuseOptions);
    return fuse.search(query);
  }, [customers, query]);

  const onSearch = debounce((value) => {
    setQuery(value);
  }, 10);
  const list = query ? filtered.map((el) => el.item) : customers;
  return (
    <Fragment>
      <Flex
        borderBottomWidth="1px"
        bg={bgColor[colorMode]}
        color={color[colorMode]}
        alignItems="center"
        justifyContent="space-between"
        p="8px 15px"
      >
        <Box>
          <Text fontWeight="600" fontSize="18px">
            Empresas
          </Text>
        </Box>
        <Box w="280px">
          <Input value={query} placeholder="Buscar por nombre o  email" onChange={(e) => onSearch(e.target.value)} />
        </Box>
      </Flex>

      <Box px={5}>
        {/* <Flex justifyContent="flex-end" alignItems="center" my="20px">
          <Box>
            <Button variant="outline" mr={5}>
              Import
            </Button>
            <Button variant="outline">Export</Button>
          </Box>
        </Flex> */}

        {/* <Box position="fixed" right={6} bottom={6} zIndex="99">
          <IconButton
            w="55px"
            height="55px"
            onClick={() => dispatch(openModal(null, 'create'))}
            icon="add"
            variantColor="purple"
            borderRadius="50%"
          />
        </Box> */}

        {loading ? (
          <Loading />
        ) : isEmpty(list) ? (
          noItemsComponent
        ) : (
          <Fragment>
            <ListCustomers list={list} onDelete={onDelete} onEdit={onEdit} />
            <ModalForm />
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};
