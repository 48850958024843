import React, { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Flex, Box, useColorMode, useDisclosure, Button, PseudoBox, Text } from '@chakra-ui/core';
import { Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody } from '@chakra-ui/core';
import { MdMenu } from 'react-icons/md';
import { FiMapPin } from 'react-icons/fi';
import useWindowSize from 'hooks/useWindowSize';
import authAction from '@redux/auth/actions';
import MenuLateral from './Menu';
import { useDeviceType } from 'lib/helpers/useDeviceType';
import 'react-toastify/dist/ReactToastify.css';
const { logout } = authAction;
const Container = ({ children }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = { light: 'white', dark: 'gray.800' };
  const color = { light: 'gray.800', dark: 'gray.100' };

  const handleTheme = async (e) => {
    const theme = !!e.target.checked ? 'dark' : 'light';
    console.log(theme);
    toggleColorMode();
  };
  const { height } = useWindowSize();
  const wMenu = '70px';
  const background = colorMode === 'dark' ? '#2D3748' : '#f4f4f4';
  const menuProps = { colorMode, height, background, handleTheme, logout };
  const deviceType = useDeviceType(navigator.userAgent);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const menu = [
    {
      id: 1,
      label: 'Inicio',
      url: '/dashboard',
    },
    {
      id: 2,
      label: 'Booking',
      url: '/bookinkg',
    },
    {
      id: 3,
      label: 'Waitlist',
      url: '/waitlist',
    },
  ];
  const MenuItems = ({ label, url }) => (
    <Link to={url}>
      <Text mt={4} mr={6} fontSize="20px">
        {label}
      </Text>
    </Link>
  );
  return (
    <Flex
      minHeight={height}
      bg={bgColor[colorMode]}
      color={color[colorMode]}
      flexDirection={deviceType.mobile ? 'column' : 'row'}
    >
      {deviceType.mobile && (
        <Fragment>
          <Box p="10px" display="flex" justifyContent="space-between">
            <Box>
              <PseudoBox color="#D64D89">
                <Box as={FiMapPin} size="35px" m="0 auto" />
              </PseudoBox>
            </Box>
            <Button ref={btnRef} onClick={onOpen}>
              <Box as={MdMenu} size="32px" cursor="pointer" />
            </Button>
          </Box>
          <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef} size="sm">
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Menu</DrawerHeader>
              <DrawerBody>
                <Box alignItems="center" flexGrow={1}>
                  {menu.map((i) => (
                    <MenuItems key={i.id} label={i.label} url={i.url} />
                  ))}
                </Box>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Fragment>
      )}
      <MenuLateral {...menuProps} />
      <Box ml={[0, 0, wMenu]} flex="1" bg={colorMode === 'dark' ? 'gray.700' : '#F9F9F9'} p={0}>
        {children}
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
      </Box>
    </Flex>
  );
};

export default Container;
