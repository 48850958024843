import actions from './actions';

const initialState = {
  confirm: false,
  loading: false,
  cargando: false,
  visible: false,
  pagination: {},
  cuenta: {},
  showModal: false,
  modalType: 'create',
  list: [],
  currentItem: {
    name: '',
    amount: '',
    repeat_every: '',
    repeat_unit: '',
    retry_times: '',
    status_after_retry: 'CANCELLED',
    trial_days: 30,
    description: '',
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.PLAN_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.PLAN_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        pagination: payload.pagination,
        query: action.query,
      };
    case actions.PLAN_FETCH_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.PLAN_CREATE_REQUEST:
      return {
        ...state,
        loadingForm: true,
      };
    case actions.PLAN_CREATE_SUCCESS:
      return {
        ...state,
        loadingForm: false,
        list: [...state.list, payload.data.data],
        visible: false,
      };
    case actions.PLAN_CREATE_ERROR:
      return {
        ...state,
        loadingForm: false,
      };
    case actions.PLAN_UPDATE_REQUEST:
      return {
        ...state,
        loadingForm: true,
      };
    case actions.PLAN_UPDATE_SUCCESS:
      console.log('payload', payload);
      return {
        ...state,
        loadingForm: false,
        list: state.list.map((x) => (x.id === payload.data.data.id ? payload.data.data : x)),
        visible: false,
        modalType: 'create',
        currentItem: initialState.currentItem,
      };
    case actions.PLAN_UPDATE_ERROR:
      return {
        ...state,
        loadingForm: false,
      };
    case actions.PLAN_DELETE_REQUEST:
      return {
        ...state,
        loadingForm: true,
      };
    case actions.PLAN_DELETE_SUCCESS:
      return {
        ...state,
        loadingForm: false,
        list: state.list.filter((item) => item.id !== payload),
      };
    case actions.PLAN_DELETE_ERROR:
      return {
        ...state,
        loadingForm: false,
        error: true,
        // message: error.
      };
    case actions.OPEN_MODAL_PLAN:
      return {
        ...state,
        visible: !state.visible,
        currentItem: payload || initialState.currentItem,
        modalType: action.modalType,
      };

    case actions.OPEN_MODAL_CONFIRM:
      return {
        ...state,
        confirm: !state.confirm,
        currentItem: payload || initialState.currentItem,
      };

    default:
      return state;
  }
};
