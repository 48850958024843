import Cookies from 'js-cookie';
import { getToken } from 'lib/utility';
import AuthHelper from 'lib/helpers/authHelper';
import { API } from 'config/api';

const actions = {
  LOGIN_REQUEST: 'auth/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGIN_ERROR: 'auth/LOGIN_ERROR',

  CHECK_AUTHORIZATION: 'auth/CHECK_AUTHORIZATION',
  LOGOUT: 'auth/LOGOUT',

  LOGIN_SUCCESS_CHECK: 'auth/LOGIN_SUCCESS_CHECK',

  /**
   * checkAuthorization
   */
  checkAuthorization: () => (dispatch) => {
    dispatch({ type: actions.CHECK_AUTHORIZATION });
    const { token } = AuthHelper.checkExpirity(getToken().get('idToken'));
    if (token) {
      let userFromCookie = Cookies.getJSON('user');
      dispatch({
        type: actions.LOGIN_SUCCESS_CHECK,
        payload: userFromCookie,
        token: token,
      });
    }
  },

  login: (data, history) => (dispatch, getState) => {
    dispatch({ type: actions.LOGIN_REQUEST });
    API.post(`/login`, data)
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: actions.LOGIN_SUCCESS,
          payload: res.data.data,
          token: res.data.token,
        });
        localStorage.setItem('id_token', res.data.token);
        Cookies.set('user', res.data, { expires: 365 });
        Cookies.set('id_token', res.data.token, { expires: 365 });
        history.push('/dashboard');
      })
      .catch((err) => {
        console.log('err', err);
        dispatch({ type: actions.LOGIN_ERROR });
      });
  },

  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;
