import actions, { getView } from './actions';

const initState = {
  collapsed: window.innerWidth > 1220 ? false : true,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  service: {},
  resource: {},
  services: [],
  resources: [],
  loading: false,
  // openKeys: preKeys,
  // current: preKeys,
};
export default function appReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.COLLPSE_CHANGE:
      return { ...state, collapsed: !state.collapsed };
    case actions.CHANGE_OPEN_KEYS:
      return { ...state, openKeys: action.openKeys };
    case actions.CHANGE_CURRENT:
      return { ...state, current: action.current };
    case actions.SET_RESOURCE:
      return { ...state, resource: action.payload };
    case actions.SET_SERVICE:
      return { ...state, service: action.payload };

    case actions.RESOURCES_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.RESOURCES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        resources: payload.data,
        query: action.query,
      };
    case actions.RESOURCES_FETCH_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.SERVICES_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.SERVICES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        services: payload.data,
        query: action.query,
      };
    case actions.SERVICES_FETCH_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.CLEAN_ALL:
      return {
        ...state,
        service: {},
        resource: {},
      };
    default:
      return state;
  }
}
