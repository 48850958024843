import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/core';
import { Button, Text, useColorMode } from '@chakra-ui/core';
import actions from '@redux/plan/actions';
const { showConfirm, deletePlan } = actions;

export default () => {
  const { colorMode } = useColorMode();
  const bgColor = { light: '#F9F9F9', dark: '#2D3748' };
  const color = { light: 'gray.800', dark: 'gray.100' };
  const dispatch = useDispatch();
  const confirm = useSelector((state) => state.plan.confirm);
  const item = useSelector((state) => state.plan.currentItem);
  console.log('item', item);
  return (
    <Modal isOpen={confirm} onClose={() => dispatch(showConfirm())}>
      <ModalOverlay />
      <ModalContent p="15px 30px" bg={bgColor[colorMode]} color={color[colorMode]}>
        <ModalCloseButton />
        <ModalBody p="10px" flexDirection="column" alignItems="center" display="flex">
          <Text fontSize="18px" mt="20px" textAlign="center">
            This will permanently delete plan {item.name}. This action cannot be reversed.
          </Text>
        </ModalBody>
        <ModalFooter display="flex" justifyItems="center" justifyContent="center">
          <Button onClick={() => dispatch(showConfirm())} mr="15px">
            Cancelar
          </Button>
          <Button onClick={() => dispatch(deletePlan(item.id))} variantColor="purple" mr={3}>
            Aceptar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
