import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalHeader, Button } from '@chakra-ui/core';
import { useColorMode, SimpleGrid } from '@chakra-ui/core';
import { ChakraInput, ChakraText } from 'components/InputForm';
import actions from '@redux/plan/actions';

const { openModal, addPlan, updatePlan } = actions;

const validationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Required'),
    amount: Yup.string().required('Required'),
    repeat_every: Yup.string().required('Required'),
    repeat_unit: Yup.string().required('Required'),
    status_after_retry: Yup.string().required('Required'),
    trial_days: Yup.string().required('Required'),
    // email: Yup.string('Required').required('Required').nullable(),
  });
};

export default ({ onClose, onUpdate }) => {
  const dispatch = useDispatch();
  const visible = useSelector((state) => state.plan.visible);
  const item = useSelector((state) => state.plan.currentItem);
  const type = useSelector((state) => state.plan.modalType);
  const loading = useSelector((state) => state.plan.loadingForm);
  const onSubmit = async (values) => {
    if (type === 'create') {
      dispatch(addPlan(values));
    } else {
      dispatch(updatePlan(values, item.id));
    }
  };
  const { colorMode } = useColorMode();
  const color = { light: 'gray.800', dark: '#fff' };
  return (
    <Formik enableReinitialize initialValues={item} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, submitCount, resetForm }) => {
        const onCerrar = () => {
          dispatch(openModal());
          resetForm();
        };
        return (
          <Modal size="xl" isOpen={visible} onClose={onCerrar} closeOnEsc={false} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent color={color[colorMode]}>
              <ModalHeader>{type === 'create' ? 'Add plan' : 'Edit plan'}</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={4}>
                <Form onSubmit={handleSubmit}>
                  <SimpleGrid columns={[1, 1, 2]} spacingX="25px">
                    <Field
                      label="Nombre del plan"
                      placeholder="Nombre del plan"
                      component={ChakraInput}
                      type="text"
                      submitCount={submitCount}
                      name="name"
                    />

                    <Field
                      label="Amount"
                      placeholder="Amount"
                      component={ChakraInput}
                      type="text"
                      submitCount={submitCount}
                      name="amount"
                    />

                    <Field
                      label="Número de unidades tiempo"
                      placeholder="Número de unidades tiempo entre los que se cobrara la suscripción. "
                      component={ChakraInput}
                      type="text"
                      submitCount={submitCount}
                      name="repeat_every"
                    />

                    <Field
                      label="Frecuencia de cobro"
                      placeholder="Especifica la frecuencia de cobro. Puede ser (week), (month) o (year)."
                      component={ChakraInput}
                      type="text"
                      submitCount={submitCount}
                      name="repeat_unit"
                    />
                    <Field
                      label="Reintentos de cobro"
                      placeholder="Número de reintentos de cobro de la suscripción. "
                      component={ChakraInput}
                      type="text"
                      submitCount={submitCount}
                      name="retry_times"
                    />
                    <Field
                      label="status_after_retry"
                      placeholder="Status en el que se pondrá la suscripción una vez que se agotaron los intentos."
                      component={ChakraInput}
                      type="text"
                      submitCount={submitCount}
                      name="status_after_retry"
                    />
                    <Field
                      label="Trial days"
                      placeholder="Número de días de prueba por defecto que tendrán las suscripciones que se creen a partir del plan creado."
                      component={ChakraInput}
                      type="text"
                      submitCount={submitCount}
                      name="trial_days"
                    />
                  </SimpleGrid>

                  <Field
                    fontSize="13px"
                    color={color[colorMode]}
                    component={ChakraText}
                    name="description"
                    label="Description"
                    placeholder="Additional notes"
                    submitCount={submitCount}
                  />
                  <Button width="100%" variantColor="purple" isLoading={loading} type="submit" height="45px" my={3}>
                    Guardar
                  </Button>
                </Form>
              </ModalBody>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};
