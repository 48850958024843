import React from 'react';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { Flex, Box, PseudoBox, Tooltip } from '@chakra-ui/core';
import { FiHome, FiCalendar, FiAward, FiSun, FiMoon, FiLogOut } from 'react-icons/fi';
import { FiBriefcase, FiMessageSquare } from 'react-icons/fi';
// import logo from "assets/images/logo.png";

const Menu = ({ height, background, logout, handleTheme, colorMode, wMenu }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Flex
        display={['none', 'none', 'block']}
        minHeight={height}
        position="fixed"
        width={wMenu}
        flexDir="column"
        borderRightWidth="1px"
      >
        <Box mt={2} mb={2} width={wMenu}>
          <Link to="/">
            <PseudoBox p={5}>
              <Box as={FiMessageSquare} size="30px" m="0 auto" color="#6B46C1" />
            </PseudoBox>
          </Link>
        </Box>
        <Box mt={5} mb={4} alignItems="center" width={wMenu}>
          <Link to="/dashboard">
            <Tooltip placement="right" label="Dashboard">
              <PseudoBox p={5} color="#b1b1b1" _hover={{ background }} cursor="pointer">
                <Box as={FiHome} size="25px" m="0 auto" />
              </PseudoBox>
            </Tooltip>
          </Link>

          <Link to="/empresas">
            <Tooltip placement="right" label="Empresas">
              <PseudoBox p={5} color="#b1b1b1" _hover={{ background }} cursor="pointer">
                <Box as={FiBriefcase} size="25px" m="0 auto" />
              </PseudoBox>
            </Tooltip>
          </Link>

          <Link to="/suscripciones">
            <Tooltip placement="right" label="Suscripciones">
              <PseudoBox p={5} color="#b1b1b1" _hover={{ background }} cursor="pointer">
                <Box as={FiCalendar} size="25px" m="0 auto" />
              </PseudoBox>
            </Tooltip>
          </Link>

          <Link to="/planes">
            <Tooltip placement="right" label="Planes">
              <PseudoBox p={5} color="#b1b1b1" _hover={{ background }} cursor="pointer">
                <Box as={FiAward} size="25px" m="0 auto" />
              </PseudoBox>
            </Tooltip>
          </Link>

          {/* <Link to="/waitlist">
            <Tooltip placement="right" label="Waitlist">
              <PseudoBox p={5} color="#b1b1b1" _hover={{ background }} cursor="pointer">
                <Box as={FiClock} size="25px" m="0 auto" />
              </PseudoBox>
            </Tooltip>
          </Link> */}

          {/* <Link to="/served">
            <Tooltip placement="right" label="Served">
              <PseudoBox p={5} color="#b1b1b1" _hover={{ background }} cursor="pointer">
                <Box as={FiCheckCircle} size="25px" m="0 auto" />
              </PseudoBox>
            </Tooltip>
          </Link> */}

          {/* <Link to="/resource">
            <Tooltip placement="right" label="Resources">
              <PseudoBox p={5} color="#b1b1b1" _hover={{ background }} cursor="pointer">
                <Box as={FiClipboard} size="25px" m="0 auto" />
              </PseudoBox>
            </Tooltip>
          </Link> */}

          {/* <Link to="/settings/services">
            <Tooltip placement="right" label="Settings">
              <PseudoBox p={5} color="#b1b1b1" _hover={{ background }} cursor="pointer">
                <Box as={FiSettings} size="25px" m="0 auto" />
              </PseudoBox>
            </Tooltip>
          </Link> */}
        </Box>

        <Box width={wMenu} pos="absolute" bottom="10px">
          <Tooltip placement="right" label={colorMode === 'dark' ? 'Cambiar a light mode' : 'Cambiar a dark mode'}>
            <PseudoBox p={5} color="#b1b1b1" _hover={{ background }} onClick={handleTheme} cursor="pointer">
              {colorMode === 'dark' ? (
                <Box as={FiSun} size="25px" m="0 auto" />
              ) : (
                <Box as={FiMoon} size="25px" m="0 auto" />
              )}
            </PseudoBox>
          </Tooltip>
          <Tooltip placement="right" label="Salir">
            <PseudoBox
              onClick={() => dispatch(logout())}
              p={5}
              color="#b1b1b1"
              _hover={{ background }}
              cursor="pointer"
            >
              <Box as={FiLogOut} size="25px" m="0 auto" />
            </PseudoBox>
          </Tooltip>
        </Box>
      </Flex>
    </>
  );
};

export default Menu;
