import React from "react";
import { Provider } from "react-redux";
import { Routes } from "./Routes";
import Boot from "./redux/boot";
import { store } from "./redux/store";
import { hot } from "react-hot-loader/root";

const App = () => (
  <Provider store={store}>
    <Routes />
  </Provider>
);
Boot()
  .then(() => App())
  .catch((error) => console.error(error));
export default process.env.NODE_ENV === "development" ? hot(App) : App;
