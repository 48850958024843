import React from 'react';
import { isEmpty } from 'lodash';
import { FormErrorMessage, FormLabel, FormControl, Input, useColorMode, Textarea } from '@chakra-ui/core';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const CreateAntField = (Component) => ({ field, form, label, submitCount, height, ...props }) => {
  const touched = form.touched[field.name];
  const submitted = submitCount > 0;
  const hasError = form.errors[field.name];
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;
  let handleInputChange = (e) => {
    let inputValue = e.target.value;
    form.setFieldValue(field.name, inputValue);
  };
  return (
    <FormControl
      isInvalid={submittedError || touchedError ? true : false}
      mb={submittedError || touchedError ? '2px' : '23px'}
    >
      {label && (
        <FormLabel mb="1px" fontSize="14px">
          {label}
        </FormLabel>
      )}
      <Input
        fontSize="14px"
        borderRadius={0}
        height={height ? height : '42px'}
        {...field}
        {...props}
        onChange={handleInputChange}
        _invalid={{ borderColor: '#e53e3e' }}
        _focus={{ borderColor: '#3182ce' }}
      />

      <FormErrorMessage fontSize="13px" mt={1}>
        {submittedError || touchedError ? hasError : false}
      </FormErrorMessage>
    </FormControl>
  );
};
const CreateTextArea = (Component) => ({ field, form, label, submitCount, height, ...props }) => {
  const touched = form.touched[field.name];
  const submitted = submitCount > 0;
  const hasError = form.errors[field.name];
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;
  let handleInputChange = (e) => {
    let inputValue = e.target.value;
    form.setFieldValue(field.name, inputValue);
  };
  return (
    <FormControl
      isInvalid={submittedError || touchedError ? true : false}
      mb={submittedError || touchedError ? '2px' : '23px'}
    >
      {label && (
        <FormLabel mb="1px" fontSize="14px">
          {label}
        </FormLabel>
      )}
      <Textarea
        color="#909090"
        borderRadius={0}
        height={height ? height : '80px'}
        {...field}
        {...props}
        onChange={handleInputChange}
        _invalid={{ borderColor: '#e53e3e' }}
        _focus={{ borderColor: '#3182ce' }}
      />

      <FormErrorMessage fontSize="13px" mt={1}>
        {submittedError || touchedError ? hasError : false}
      </FormErrorMessage>
    </FormControl>
  );
};

const CreateSelect = (Component) => ({
  field,
  form,
  label,
  submitCount,
  height,
  options,
  isMulti = false,
  onSelect,
  ...props
}) => {
  const touched = form.touched[field.name];
  const submitted = submitCount > 0;
  const hasError = form.errors[field.name];
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colorMode } = useColorMode();

  const onChange = (option) => {
    if (isEmpty(option)) {
      form.setFieldValue(field.name, isMulti ? [] : '');
    } else {
      form.setFieldValue(field.name, isMulti ? option.map((item) => item.value) : option.value);
    }
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : '';
    }
  };

  const colourStyles = {
    control: (styles) => {
      return {
        ...styles,
        backgroundColor: colorMode === 'dark' ? '#394353' : '#fff',
        color: colorMode === 'dark' ? '#394353' : '',
        borderColor: colorMode === 'dark' ? 'rgba(255,255,255,0.04)' : '#E2E8F0',
        borderRadius: 0,
        height: height ? height : '42px',
        // This line disable the blue border
        boxShadow: 'none',
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: colorMode === 'dark' ? '#394353' : '#fff',
        color: colorMode === 'dark' ? '#fff' : '',
        padding: '5px 15px',
        ':hover': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : colorMode === 'dark' ? '#1A202C' : '#deebff'),
          color: colorMode === 'dark' ? '#fff' : '',
        },
      };
    },
    singleValue: (styles) => ({ ...styles, color: colorMode === 'dark' ? '#fff' : '' }),
    indicatorSeparator: (styles) => ({ display: 'none' }),
  };
  return (
    <FormControl
      isInvalid={submittedError || touchedError ? true : false}
      mb={submittedError || touchedError ? '2px' : '23px'}
    >
      {label && (
        <FormLabel mb="1px" fontSize="14px">
          {label}
        </FormLabel>
      )}
      <Select
        placeholder={props.placeholder || ''}
        styles={colourStyles}
        isMulti={isMulti}
        value={getValue()}
        onChange={onChange}
        name={field.name}
        options={options}
      />

      <FormErrorMessage fontSize="13px" mt={1}>
        {submittedError || touchedError ? hasError : false}
      </FormErrorMessage>
    </FormControl>
  );
};

const CreateSelectCreatable = (Component) => ({
  field,
  form,
  label,
  submitCount,
  height,
  options,
  isMulti = false,
  onSelect,
  ...props
}) => {
  const touched = form.touched[field.name];
  const submitted = submitCount > 0;
  const hasError = form.errors[field.name];
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colorMode } = useColorMode();

  const onChange = (option) => {
    if (isEmpty(option)) {
      form.setFieldValue(field.name, isMulti ? [] : '');
    } else {
      form.setFieldValue(field.name, isMulti ? option.map((item) => item.value) : option.value);
    }
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : '';
    }
  };

  const colourStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#AAB7C7',
      };
    },
    control: (styles) => {
      return {
        ...styles,
        backgroundColor: colorMode === 'dark' ? '#394353' : '#fff',
        color: colorMode === 'dark' ? '#394353' : '',
        fontSize: '14px',
        borderColor: colorMode === 'dark' ? 'rgba(255,255,255,0.04)' : '#E2E8F0',
        borderRadius: 0,
        height: height ? height : '42px',
        paddingLeft: '8px',
        // This line disable the blue border
        boxShadow: 'none',
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: colorMode === 'dark' ? '#394353' : '#fff',
        color: colorMode === 'dark' ? '#fff' : '',
        padding: '5px 15px',
        ':hover': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : colorMode === 'dark' ? '#1A202C' : '#deebff'),
          color: colorMode === 'dark' ? '#fff' : '',
        },
      };
    },
    singleValue: (styles) => ({ ...styles, color: colorMode === 'dark' ? '#fff' : '' }),
    indicatorSeparator: (styles) => ({ display: 'none' }),
  };
  return (
    <FormControl
      isInvalid={submittedError || touchedError ? true : false}
      mb={submittedError || touchedError ? '2px' : '23px'}
    >
      {label && (
        <FormLabel mb="1px" fontSize="14px">
          {label}
        </FormLabel>
      )}
      <CreatableSelect
        isClearable
        placeholder={props.placeholder || ''}
        styles={colourStyles}
        isMulti={isMulti}
        value={getValue()}
        onChange={onChange}
        name={field.name}
        options={options}
      />

      <FormErrorMessage fontSize="13px" mt={1}>
        {submittedError || touchedError ? hasError : false}
      </FormErrorMessage>
    </FormControl>
  );
};
export const ChakraInput = CreateAntField(Input);
export const ChakraSelect = CreateSelect(Select);
export const SelectCreatable = CreateSelectCreatable(Select);
export const ChakraText = CreateTextArea(Textarea);
