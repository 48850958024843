import { all, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import { clearToken } from "lib/utility";
import actions from "./actions";

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken();
    yield put(push("/"));
  });
}

export default function* rootSaga() {
  yield all([fork(logout)]);
}
