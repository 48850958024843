import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { multiClientMiddleware } from 'redux-axios-middleware';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../redux/reducers';
import rootSaga from '../redux/sagas';
import clients from './clients';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);

const reducer = persistReducer(
  {
    key: 'rrsb', // key is required
    storage, // storage is now required
    whitelist: ['app'],
  },
  combineReducers({
    ...rootReducer,
    form: formReducer,
  })
);

/* simplified React Promise Middleware */
function promiseMiddleware({ dispatch }) {
  function isPromise(val) {
    return val && typeof val.then === 'function';
  }

  return (next) => (action) => {
    return isPromise(action.payload)
      ? action.payload.then(
          (result) => dispatch({ ...action, payload: result }),
          (error) => dispatch({ ...action, payload: error, error: true })
        )
      : next(action);
  };
}

const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      ({ getState, dispatch }, req) => {
        if (getState().auth.token) {
          req.headers['token'] = getState().auth.token;
        }
        return req;
      },
    ],
    response: [
      {
        success: ({ dispatch }, response) => {
          // Response interception
          return response;
        },
        error: ({ dispatch }, error) => {
          console.log(error.response.data.message);
          toast.error(error.response.data.err.description);
          // Response Error Interception
          return Promise.reject(error);
        },
      },
    ],
  },
};

const middlewares = [
  thunk,
  sagaMiddleware,
  routeMiddleware,
  promiseMiddleware,
  multiClientMiddleware(clients, axiosMiddlewareOptions),
];

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');
  middlewares.push(createLogger({ collapsed: true }));
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* istanbul ignore next */
const configStore = (initialState = {}) => {
  const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middlewares))(createStore);

  const store = createStoreWithMiddleware(reducer, initialState);

  sagaMiddleware.run(rootSaga);

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();
export { store, persistor, history };
