import { API } from 'config/api';

const action = {
  CUSTOMER_FETCH_REQUEST: 'empresa/CUSTOMER_FETCH_REQUEST',
  CUSTOMER_FETCH_SUCCESS: 'empresa/CUSTOMER_FETCH_SUCCESS',
  CUSTOMER_FETCH_ERROR: 'empresa/CUSTOMER_FETCH_ERROR',

  CUSTOMERS_CONTACTO_REQUEST: 'empresa/CUSTOMERS_CONTACTO_REQUEST',
  CUSTOMERS_CONTACTO_SUCCESS: 'empresa/CUSTOMERS_CONTACTO_SUCCESS',
  CUSTOMERS_CONTACTO_ERROR: 'empresa/CUSTOMER_FETCH_ERROR',

  CUSTOMER_CREATE_REQUEST: 'empresa/CUSTOMER_CREATE_REQUEST',
  CUSTOMER_CREATE_SUCCESS: 'empresa/CUSTOMER_CREATE_SUCCESS',
  CUSTOMER_CREATE_ERROR: 'empresa/CUSTOMER_CREATE_ERROR',

  CUSTOMER_UPDATE_REQUEST: 'empresa/CUSTOMER_UPDATE_REQUEST',
  CUSTOMER_UPDATE_SUCCESS: 'empresa/CUSTOMER_UPDATE_SUCCESS',
  CUSTOMER_UPDATE_ERROR: 'empresa/CUSTOMER_UPDATE_ERROR',

  CUSTOMER_DELETE_REQUEST: 'empresa/CUSTOMER_DELETE_REQUEST',
  CUSTOMER_DELETE_SUCCESS: 'empresa/CUSTOMER_DELETE_SUCCESS',
  CUSTOMER_DELETE_ERROR: 'empresa/CUSTOMER_DELETE_ERROR',

  UPDATE_ADMIN_CUSTOMERS_SUCCESS: 'empresa/UPDATE_ADMIN_CUSTOMERS_SUCCESS',
  CLOSE_DRAW_CONTACTOS: 'empresa/CLOSE_DRAW_CONTACTOS',

  OPEN_MODAL_CUSTOMER: 'empresa/OPEN_MODAL_CUSTOMER',

  getCustomers: () => (dispatch) => {
    dispatch({ type: action.CUSTOMER_FETCH_REQUEST });
    API.get(`/empresas`)
      .then((res) => {
        dispatch({ type: action.CUSTOMER_FETCH_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: action.CUSTOMER_FETCH_ERROR });
      });
  },

  addCustomer: (data) => ({
    types: [action.CUSTOMER_CREATE_REQUEST, action.CUSTOMER_CREATE_SUCCESS, action.CUSTOMER_CREATE_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'POST',
        url: '/customer',
        data,
      },
    },
  }),

  updateCustomer: (data, id) => ({
    types: [action.CUSTOMER_UPDATE_REQUEST, action.CUSTOMER_UPDATE_SUCCESS, action.CUSTOMER_UPDATE_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'PUT',
        url: `/customer/${id}/update`,
        data,
      },
    },
  }),

  deleteCustomer: (id) => (dispatch) => {
    dispatch({ type: action.CUSTOMER_DELETE_REQUEST });
    API.delete(`/customer/${id}/delete`)
      .then((res) => {
        dispatch({ type: action.CUSTOMER_DELETE_SUCCESS, payload: id });
      })
      .catch((err) => {
        dispatch({ type: action.CUSTOMER_DELETE_ERROR });
      });
  },

  // deleteCustomer: (id) => ({
  //   types: [action.CUSTOMER_DELETE_REQUEST, action.CUSTOMER_DELETE_SUCCESS, action.CUSTOMER_DELETE_ERROR],
  //   payload: {
  //     client: 'default',
  //     request: {
  //       method: 'DELETE',
  //       url: `/customer/${id}/delete`,
  //     },
  //   },
  // }),

  openModal: (payload, modalType) => (dispatch) => {
    dispatch({ type: action.OPEN_MODAL_CUSTOMER, payload, modalType });
  },
};
export default action;
