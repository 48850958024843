import { Map } from 'immutable';
import _, { chain } from 'lodash';

export function clearToken() {
  localStorage.removeItem('id_token');
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function groupByCategory(list) {
  return _(list)
    .groupBy('category')
    .map((objs, key) => ({
      category: key,
      list: objs,
      total: objs.length,
    }))
    .orderBy(['category'], ['asc'])
    .value();
}

export function getCategorias(list) {
  return chain(list)
    .groupBy('category')
    .map((value, key) => ({ label: key, value: key }))
    .orderBy(['id'], ['asc'])
    .value();
}
