import React from 'react';
import moment from 'moment';
import { AiOutlineMail, AiOutlineNumber } from 'react-icons/ai';
// import { FiEdit, FiDelete } from 'react-icons/fi';
import { Box, PseudoBox, Flex, useColorMode, Avatar, Text, Stack } from '@chakra-ui/core';
import { useDeviceType } from 'lib/helpers/useDeviceType';
// import actions from '@redux/customer/actions';

// const { updateAgencias } = actions;

export default ({ list, areas, history, onEdit, onDelete, onUpdate, onOpenDraw }) => {
  const { colorMode } = useColorMode();
  const bgColor = { light: 'white', dark: 'gray.800' };
  //   const dispatch = useDispatch();

  const deviceType = useDeviceType(navigator.userAgent);
  const background = colorMode === 'dark' ? '#2D3748' : '#f4f4f4';
  return (
    <Box my="20px">
      <Box borderWidth="1px">
        {list.map((item, index) => {
          return (
            <PseudoBox
              bg={bgColor[colorMode]}
              display="flex"
              key={index}
              px={4}
              py={2}
              borderTopWidth="1px"
              _first={{ borderTopWidth: 0 }}
              _hover={{ background }}
            >
              <Flex width="45px" pos="relative" mr="5px">
                <Avatar size="sm" name={`${item.firstName} ${item.lastName}`} />
              </Flex>
              <Flex flex="1">
                <Box>
                  <Box display="flex" mb="2px">
                    <Text fontWeight="600" fontSize="15px">
                      {item.user.nombre} {item.user.apellidos}
                    </Text>
                  </Box>
                  <Stack isInline={deviceType.mobile ? false : true} color="gray.500" mt="3px">
                    <Box display="flex" alignItems="center">
                      <Box as={AiOutlineNumber} size="11px" />
                      <Text ml="5px" fontSize="12px">
                        {item.user.telefono}
                      </Text>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box as={AiOutlineMail} size="11px" />
                      <Text ml="5px" fontSize="12px">
                        {item.user.email}
                      </Text>
                    </Box>
                  </Stack>
                </Box>
              </Flex>
              <Flex
                flex="1"
                alignItems="center"
                direction="row"
                justifyContent="center"
                display={['none', 'none', 'flex']}
              >
                <Box></Box>
              </Flex>
              <Flex
                color="gray.400"
                alignItems="center"
                width="120px"
                justifyContent="center"
                display={['none', 'none', 'flex']}
                mx={3}
              >
                <Text fontSize="14px">{moment(item.createdAt).fromNow()}</Text>
              </Flex>
              {/* <Flex color="gray.500" alignItems="center">
                <Stack isInline={deviceType.mobile ? false : true} spacing={3}>
                  <Box>
                    <Box onClick={() => onEdit(item)} as={FiEdit} size="22px" cursor="pointer" />
                  </Box>
                  <Box>
                    <Box onClick={() => onDelete(item._id)} as={FiDelete} size="22px" cursor="pointer" />
                  </Box>
                </Stack>
              </Flex> */}
            </PseudoBox>
          );
        })}
      </Box>
    </Box>
  );
};
